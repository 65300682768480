<template lang="pug">
main.container
    h1
        img(src="@/assets/img/icon-ab-test.svg")
        br
        | A/B Test Rechner

    //- https://converlytics.com/tools/signifikanz-rechner-ab-test/

    .card.card-body
        .row
            .col-md-6.text-center
                legend A - Original

                .row.row-cols-2
                    .col
                        label Anzahl Besucher
                        input.form-control.font-control-lg(v-model.number="visitorsA")
                    .col
                        label Anzahl Konversionen
                        input.form-control.font-control-lg(v-model.number="conversionsA")

                .result(v-if="conversionRateA")
                    label Konversionsrate
                    strong {{ conversionRateA.toFixed(1).replace('.', ',') }} %

            .col-md-6.text-center
                legend B - Variante

                .row.row-cols-2
                    .col
                        label Anzahl Besucher
                        input.form-control.font-control-lg(v-model.number="visitorsB")
                    .col
                        label Anzahl Konversionen
                        input.form-control.font-control-lg(v-model.number="conversionsB")

                .result(v-if="conversionRateB")
                    label Konversionsrate
                    strong(:class="conversionRateBClass") {{ conversionRateB.toFixed(1).replace('.', ',') }} %

    template(v-if="confidence && confidence >= 95")
        .text-center.text-success.mt-2 Ergebnis ist statistisch signifikant. Konfidenzniveau beträgt {{ confidence.toFixed(1) }}%

    template(v-if="confidence && confidence < 95")
        .text-center.text-danger.mt-2 Nicht signifikant. Konfidenzniveau beträgt nur {{ confidence.toFixed(1) }}%
</template>

<script>
import analyse from 'chi-square-p-value'

export default {
    data () {
        return {
            visitorsA: null,
            visitorsB: null,
            conversionsA: null,
            conversionsB: null
        }
    },
    computed: {
        conversionRateA () {
            if (!this.visitorsA || !this.conversionsA) {
                return
            }

            return this.conversionsA / this.visitorsA * 100
        },
        conversionRateB () {
            if (!this.visitorsB || !this.conversionsB) {
                return
            }

            return this.conversionsB / this.visitorsB * 100
        },
        conversionRateBClass () {
            if (!this.conversionRateA || !this.conversionRateB) {
                return
            }

            if (this.conversionRateA === this.conversionRateB) {
                return
            }

            return this.conversionRateB > this.conversionRateA ? 'text-success' : 'text-danger'
        },
        confidence () {
            if (!this.visitorsA || !this.visitorsB || !this.conversionsA || !this.conversionsB) {
                return
            }

            const { pValue } = analyse([
                [this.visitorsA, this.visitorsB],
                [this.conversionsA, this.conversionsB]
            ])

            return (1 - pValue) * 100
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";

h1 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: $primary;
    text-decoration-style: double;

    img {
        margin-bottom: 1rem;
        width: 5rem;
    }
}

legend {
    font-weight: $font-weight-bold;
    margin-bottom: 2rem;
}

label {
    margin-bottom: 0.5rem;
}

input {
    text-align: center;
}

.result {
    margin-top: 1.5rem;

    strong {
        display: block;
        font-size: 1.5rem;
    }
}
</style>
