<template lang="pug">
main.container
    h1
        img(src="@/assets/img/icon-tage.svg")
        br
        | Tage Rechner

    .row.justify-content-center.align-items-center.my-4
        .col-auto
            label.mb-0 Region:
        .col-auto
            select.form-select(v-model="region")
                option(v-for="region of regions", :value="region.value") {{ region.text }}

    .card.card-body.mb-5
        legend Anzahl an Tagen zwischen zwei Kalenderdaten
        .row.align-items-center
            .col-md-4
                label von
                datepicker.mb-2(
                    textInput,
                    locale="de-DE",
                    format="dd.MM.yyyy",
                    monthChangeOnScroll="inverse",
                    :autoApply="true",
                    :enableTimePicker="false",
                    v-model="dateFrom"
                )
                label bis
                datepicker.mb-2(
                    textInput,
                    locale="de-DE",
                    format="dd.MM.yyyy",
                    monthChangeOnScroll="inverse",
                    :autoApply="true",
                    :enableTimePicker="false",
                    v-model="dateTo"
                )

            .col-md-4.text-center(v-if="numDays")
                .fw-bold Anzahl an Tagen
                .small (inkl. Start- exkl. Enddatum)
                .fs-2 {{ numDays }}
            .col-md-4.text-center(v-if="numWorkingDays")
                .fw-bold Anzahl an Werktagen
                .small (ohne Samstage, Sonntage, und Feiertage)
                .fs-2 {{ numWorkingDays }}

    .card.card-body.mb-5
        legend Tage addieren / subtrahieren
        .row.g-3.align-items-center.mb-4
            .col-md-4
                datepicker(
                    textInput,
                    locale="de-DE",
                    format="dd.MM.yyyy",
                    monthChangeOnScroll="inverse",
                    :autoApply="true",
                    :enableTimePicker="false",
                    v-model="dateCalc"
                )
            .col-auto: span.form-text +
            .col-auto
                input.form-control(v-model="addCalc")
            .col-auto
                select.form-select(v-model="daysCalc")
                    option(value="ALL_DAYS") Tage
                    option(value="WORKING_DAYS") Werktage
            .col-auto.ms-auto
                input.form-control(readonly, v-model="dateAdd")
        .row.g-3.align-items-center.mb-4
            .col-md-4
            .col-auto: span.form-text -
            .col-auto
                input.form-control(v-model="subCalc")
            .col-auto
                select.form-select(v-model="daysCalc")
                    option(value="ALL_DAYS") Tage
                    option(value="WORKING_DAYS") Werktage
            .col-auto.ms-auto
                input.form-control(readonly, v-model="dateSub")
</template>

<script>
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import { isHoliday } from 'feiertagejs'
import { DateTime } from 'luxon'

const regions = [
    { text: 'bundesweit', value: 'BUND' },
    { text: 'Baden-Württemberg', value: 'BW' },
    { text: 'Bayern', value: 'BY' },
    { text: 'Berlin', value: 'BE' },
    { text: 'Brandenburg', value: 'BB' },
    { text: 'Bremen', value: 'HB' },
    { text: 'Hessen', value: 'HE' },
    { text: 'Hamburg', value: 'HH' },
    { text: 'Mecklenburg-Vorpommern', value: 'MV' },
    { text: 'Niedersachsen', value: 'NI' },
    { text: 'Nordrhein-Westfalen', value: 'NW' },
    { text: 'Rheinland-Pfalz', value: 'RP' },
    { text: 'Saarland', value: 'SL' },
    { text: 'Sachsen', value: 'SN' },
    { text: 'Sachsen-Anhalt', value: 'ST' },
    { text: 'Schleswig-Holstein', value: 'SH' },
    { text: 'Thüringen', value: 'TH' }
]

export default {
    components: {
        Datepicker
    },
    data () {
        return {
            dateFrom: new Date(),
            dateTo: null,
            region: 'BUND',
            regions,
            dateCalc: new Date(),
            addCalc: null,
            subCalc: null,
            daysCalc: 'ALL_DAYS' // or 'WORKING_DAYS'
        }
    },
    computed: {
        numDays () {
            if (!this.dateFrom || !this.dateTo) {
                return
            }

            const start = DateTime.fromJSDate(this.dateFrom)
            const end = DateTime.fromJSDate(this.dateTo)

            return Math.round(end.diff(start, 'days').days)
        },
        numWorkingDays () {
            if (!this.dateFrom || !this.dateTo) {
                return
            }

            return this.getNumWorkingDays(this.dateFrom, this.dateTo, this.region)
        },
        dateAdd () {
            if (!this.dateCalc || !this.addCalc || !this.daysCalc) {
                return
            }

            if (this.daysCalc === 'ALL_DAYS') {
                return DateTime.fromJSDate(this.dateCalc).plus({ days: this.addCalc }).toFormat('dd.MM.yyyy')
            }

            let numWorkingDays = 0
            const start = DateTime.fromJSDate(this.dateCalc).plus({ days: 1 }) // start counting tomorrow
            while (this.getNumWorkingDays(start.toJSDate(), start.plus({ days: numWorkingDays }).toJSDate(), this.region) < this.addCalc) {
                numWorkingDays++
            }

            console.log(numWorkingDays - this.addCalc)

            return DateTime.fromJSDate(this.dateCalc).plus({ days: numWorkingDays }).toFormat('dd.MM.yyyy')
        },
        dateSub () {
            if (!this.dateCalc || !this.subCalc || !this.daysCalc) {
                return
            }

            if (this.daysCalc === 'ALL_DAYS') {
                return DateTime.fromJSDate(this.dateCalc).minus({ days: this.subCalc }).toFormat('dd.MM.yyyy')
            }

            let numWorkingDays = parseInt(this.subCalc)
            const start = DateTime.fromJSDate(this.dateCalc).minus({ days: 1 }) // start counting yesterday
            while (this.getNumWorkingDays(start.toJSDate(), start.minus({ days: numWorkingDays }).toJSDate(), this.region) < this.subCalc) {
                numWorkingDays++
            }

            return DateTime.fromJSDate(this.dateCalc).minus({ days: numWorkingDays }).toFormat('dd.MM.yyyy')
        }
    },
    methods: {
        getNumWorkingDays (from, to, region) {
            if (!from || !to) {
                return
            }

            const start = DateTime.fromJSDate(from)
            const end = DateTime.fromJSDate(to)

            const numDays = Math.abs(Math.round(end.diff(start, 'days').days))
            console.log(numDays)

            const days = new Array(numDays)
                .fill(undefined)
                .map((val, idx) => start.plus({ days: idx }))
                .filter(day => (day.weekday !== 6 && day.weekday !== 7))
                .filter(day => !isHoliday(day.toJSDate(), region))

            return days.length
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";
@import "vue3-date-time-picker/src/Vue3DatePicker/style/main.scss";

h1 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: $primary;
    text-decoration-style: double;

    img {
        margin-bottom: 1rem;
        width: 5rem;
    }
}

legend {
    font-weight: $font-weight-bold;
    margin-bottom: 2rem;
}

label {
    margin-bottom: 0.5rem;
}

input {
    text-align: center;
    max-width: 10rem;
}

.form-text {
    display: inline-block;
    text-align: center;
    width: 1rem;
}

.ms-auto {
    .input-group {
        max-width: 10rem;
    }
}

.result {
    margin-top: 1.5rem;

    strong {
        display: block;
        font-size: 1.5rem;
    }
}
</style>
