import Home from '@/views/Home.vue'
import ToolABTestRechner from '@/views/Tools/ABTestRechner.vue'
import ToolProzentRechner from '@/views/Tools/ProzentRechner.vue'
import ToolTageRechner from '@/views/Tools/TageRechner.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/ab-test-rechner',
        component: ToolABTestRechner
    },
    {
        path: '/prozent-rechner',
        component: ToolProzentRechner
    },
    {
        path: '/tage-rechner',
        component: ToolTageRechner
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
