<template lang="pug">
main.container
    h1
        img(src="@/assets/img/icon-prozent.svg")
        br
        | Prozent Rechner

    .card.card-body.mb-5
        legend Prozentuale Zunahme/Abnahme berechnen
        .row.g-3.align-items-center
            .col-auto: span.form-text Von
            .col-auto: input.form-control(v-model="zunahme_von")
            .col-auto: span.form-text zu
            .col-auto: input.form-control(v-model="zunahme_zu")
            .col-auto: span.form-text sind es wieviel %?
            .col-auto.ms-auto
                .input-group
                    input.form-control(readonly, v-model="zunahme_ergebnis")
                    span.input-group-text %

    .card.card-body.mb-5
        legend Prozente addieren / subtrahieren
        .row.g-3.align-items-center.mb-4
            .col-auto: input.form-control(v-model="addieren_1")
            .col-auto: span.form-text +
            .col-auto
                .input-group
                    input.form-control(v-model="addieren_2")
                    span.input-group-text %
            .col-auto.ms-auto: input.form-control(readonly, v-model="addieren_ergebnis")
        .row.g-3.align-items-center
            .col-auto: input.form-control(v-model="subtrahieren_1")
            .col-auto: span.form-text -
            .col-auto
                .input-group
                    input.form-control(v-model="subtrahieren_2")
                    span.input-group-text %
            .col-auto.ms-auto: input.form-control(readonly, v-model="subtrahieren_ergebnis")

    .card.card-body.mb-5
        legend Prozentsatz berechnen
        .row.g-3.align-items-center
            .col-auto: input.form-control(v-model="prozentsatz_prozentwert")
            .col-auto: span.form-text sind wie viel Prozent von
            .col-auto: input.form-control(v-model="prozentsatz_grundwert")
            .col-auto: span.form-text ?
            .col-auto.ms-auto
                .input-group
                    input.form-control(readonly, v-model="prozentsatz_ergebnis")
                    span.input-group-text %

    .card.card-body.mb-5
        legend Prozentwert berechnen
        .row.g-3.align-items-center
            .col-auto: span.form-text Wie viel sind
            .col-auto
                .input-group
                    input.form-control(v-model="prozentwert_prozentsatz")
                    span.input-group-text %
            .col-auto: span.form-text von
            .col-auto: input.form-control(v-model="prozentwert_grundwert")
            .col-auto: span.form-text ?
            .col-auto.ms-auto: input.form-control(readonly, v-model="prozentwert_ergebnis")

    .card.card-body.mb-5
        legend Grundwert berechnen
        .row.g-3.align-items-center
            .col-auto: input.form-control(v-model="grundwert_prozentwert")
            .col-auto: span.form-text sind
            .col-auto
                .input-group
                    input.form-control(v-model="grundwert_prozentsatz")
                    span.input-group-text %
            .col-auto: span.form-text von wie viel?
            .col-auto.ms-auto: input.form-control(readonly, v-model="grundwert_ergebnis")
</template>

<script>
export default {
    data () {
        return {
            zunahme_von: null,
            zunahme_zu: null,
            addieren_1: null,
            addieren_2: null,
            subtrahieren_1: null,
            subtrahieren_2: null,
            prozentsatz_prozentwert: null,
            prozentsatz_grundwert: null,
            prozentwert_prozentsatz: null,
            prozentwert_grundwert: null,
            grundwert_prozentwert: null,
            grundwert_prozentsatz: null
        }
    },
    computed: {
        zunahme_ergebnis () {
            if (!this.zunahme_von || !this.zunahme_zu) {
                return
            }

            const [a, b] = this.parseNumbers(this.zunahme_von, this.zunahme_zu)

            return (((b - a) * 100) / a).toFixed(1).replace('.', ',')
        },
        addieren_ergebnis () {
            if (!this.addieren_1 || !this.addieren_2) {
                return
            }

            const [a, b] = this.parseNumbers(this.addieren_1, this.addieren_2)

            return (a * (1 + (b / 100))).toFixed(1).replace('.', ',')
        },
        subtrahieren_ergebnis () {
            if (!this.subtrahieren_1 || !this.subtrahieren_2) {
                return
            }

            const [a, b] = this.parseNumbers(this.subtrahieren_1, this.subtrahieren_2)

            return (a * (1 - (b / 100))).toFixed(1).replace('.', ',')
        },
        prozentsatz_ergebnis () {
            if (!this.prozentsatz_prozentwert || !this.prozentsatz_grundwert) {
                return
            }

            const [a, b] = this.parseNumbers(this.prozentsatz_prozentwert, this.prozentsatz_grundwert)

            return ((a * 100) / b).toFixed(1).replace('.', ',')
        },
        prozentwert_ergebnis () {
            if (!this.prozentwert_prozentsatz || !this.prozentwert_grundwert) {
                return
            }

            const [a, b] = this.parseNumbers(this.prozentwert_prozentsatz, this.prozentwert_grundwert)

            return ((a * b) / 100).toFixed(1).replace('.', ',')
        },
        grundwert_ergebnis () {
            if (!this.grundwert_prozentwert || !this.grundwert_prozentsatz) {
                return
            }

            const [a, b] = this.parseNumbers(this.grundwert_prozentwert, this.grundwert_prozentsatz)

            return ((a * 100) / b).toFixed(1).replace('.', ',')
        }
    },
    methods: {
        parseNumbers (...args) {
            const result = []
            for (const arg of args) {
                result.push(arg
                    .split('.').join('_')
                    .split(',').join('.')
                    .split('_').join(',')
                )
            }
            return result
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main";

h1 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: $primary;
    text-decoration-style: double;

    img {
        margin-bottom: 1rem;
        width: 5rem;
    }
}

legend {
    font-weight: $font-weight-bold;
    margin-bottom: 2rem;
}

label {
    margin-bottom: 0.5rem;
}

input {
    text-align: center;
    max-width: 10rem;
}

.ms-auto {
    .input-group {
        max-width: 10rem;
    }
}

.result {
    margin-top: 1.5rem;

    strong {
        display: block;
        font-size: 1.5rem;
    }
}
</style>
